<template>
<div id="locate-us">
    <NavBar />
    <LocateComponent />
    <GetInTouch class="move-up" />
    <TheFooter />
</div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import TheFooter from '@/components/TheFooter.vue';
import LocateComponent from '@/components/user/LocateComponent.vue';
import GetInTouch from '@/components/user/GetInTouch.vue';

export default {
	components: {
        NavBar,
		TheFooter,
        LocateComponent,
        GetInTouch
	}
}
</script>

<style scoped>

.move-up { margin-top: -100px; }

</style>
